import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Tooltip" description="Accessible tooltips component for React" mdxType="SEO" />
    <h1 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h1>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/tooltip"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/tooltip`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#tooltip"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#tooltip`}</a></li>
    </ul>
    <p>{`When the user's mouse or focus rests on an element, a non-interactive popup is displayed near it.`}</p>
    <p>{`A couple notes on using tooltips:`}</p>
    <ul>
      <li parentName="ul">{`Do not use tooltips for information vital to task completion. The elements they are attached to should usually make sense on their own, but a tooltip can help provide a little bit more information--especially for new users of your app.`}</li>
      <li parentName="ul">{`Keep the content minimal, they are not intended to hide critical content.`}</li>
      <li parentName="ul">{`If you want interactive content, you can use a `}<a parentName="li" {...{
          "href": "/dialog"
        }}>{`Dialog`}</a>{`.`}</li>
    </ul>
    <p><em parentName="p">{`Touch Events`}</em>{`: Touch events are currently not supported. There's not a lot of research or examples of these types of tooltips on mobile. We have some ideas but need to validate them first before implementing. Please adjust your interfaces on mobile to account for this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <div>
      <Tooltip label="Notifications">
        <button style={{ fontSize: 25 }}>
          <span>🔔</span>
        </button>
      </Tooltip>
      <Tooltip label="Settings">
        <button style={{ fontSize: 25 }}>
          <span aria-hidden>⚙️</span>
        </button>
      </Tooltip>

      <div style={{ float: "right" }}>
        <Tooltip label="Notifications" aria-label="3 Notifications">
          <button style={{ fontSize: 25 }}>
            <span>🔔</span>
            <span>3</span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/tooltip`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/tooltip`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/tooltip
# or
yarn add @reach/tooltip
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import Tooltip, { useTooltip, TooltipPopup } from "@reach/tooltip";
import "@reach/tooltip/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Tooltip label="Save">
      <button style={{ fontSize: 25 }}>💾</button>
    </Tooltip>
  );
}
`}</code></pre>
    <p>{`Sometimes the button contains information that is relevant to the user, like a badge showing the count for notifications. In these cases we want the screen reader user to know both the content in the tooltip, but also the content in the badge. For screen reader users, the only content announced to them is whatever is in the tooltip. For these cases, use the `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Tooltip
      // What the tooltip renders
      label="Notifications"
      // What the screen reader announces
      aria-label="3 Notifications"
    >
      <button style={{ fontSize: 25 }}>🔔 3</button>
    </Tooltip>
  );
}
`}</code></pre>
    <p>{`All props passed to `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` will go to the popover.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Tooltip
      label="Fall in love all over again"
      style={{
        background: "hsla(0, 0%, 0%, 0.75)",
        color: "white",
        border: "none",
        borderRadius: "4px",
        padding: "0.5em 1em",
      }}
    >
      <button style={{ fontSize: 25 }}>❤️</button>
    </Tooltip>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "tooltip-1"
    }}>{`Tooltip`}</h3>
    <p>{`Renders … you guessed it: a tooltip!`}</p>
    <h4 {...{
      "id": "tooltip-css-selectors"
    }}>{`Tooltip CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-tooltip] {
}
`}</code></pre>
    <h3 {...{
      "id": "usetooltip-tooltippopup"
    }}>{`useTooltip, TooltipPopup`}</h3>
    <p><inlineCode parentName="p">{`Tooltip`}</inlineCode>{` is built on top of the `}<inlineCode parentName="p">{`useTooltip`}</inlineCode>{` hook and `}<inlineCode parentName="p">{`TooltipPopup`}</inlineCode>{`.`}</p>
    <p>{`You may want to drop down to this lower level API if you need animation or you want to add the triangles to the tooltip. `}<a parentName="p" {...{
        "href": "#demos"
      }}>{`Check out the demos below for examples.`}</a></p>
    <h2 {...{
      "id": "demos"
    }}>{`Demos`}</h2>
    <h3 {...{
      "id": "triangle-pointers-and-custom-styles"
    }}>{`Triangle pointers and custom styles`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  // Center the tooltip, but collisions will win
  const centered = (triggerRect, tooltipRect) => {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - 2;
    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.bottom + 8 + window.scrollY,
    };
  };

  function TriangleTooltip({ children, label, "aria-label": ariaLabel }) {
    // get the props from useTooltip
    const [trigger, tooltip] = useTooltip();

    // destructure off what we need to position the triangle
    const { isVisible, triggerRect } = tooltip;

    return (
      <React.Fragment>
        {React.cloneElement(children, trigger)}

        {isVisible && (
          // The Triangle. We position it relative to the trigger, not the popup
          // so that collisions don't have a triangle pointing off to nowhere.
          // Using a Portal may seem a little extreme, but we can keep the
          // positioning logic simpler here instead of needing to consider
          // the popup's position relative to the trigger and collisions
          <Portal>
            <div
              style={{
                position: "absolute",
                left:
                  triggerRect && triggerRect.left - 10 + triggerRect.width / 2,
                top: triggerRect && triggerRect.bottom + window.scrollY,
                width: 0,
                height: 0,
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderBottom: "10px solid black",
              }}
            />
          </Portal>
        )}
        <TooltipPopup
          {...tooltip}
          label={label}
          aria-label={ariaLabel}
          style={{
            background: "black",
            color: "white",
            border: "none",
            borderRadius: "3px",
            padding: "0.5em 1em",
          }}
          position={centered}
        />
      </React.Fragment>
    );
  }

  return (
    <div>
      <TriangleTooltip label="Notifications Really Long to Trigger Collision">
        <button style={{ fontSize: 25 }}>
          <span aria-hidden>🔔</span>
        </button>
      </TriangleTooltip>
      <TriangleTooltip label="Settings">
        <button style={{ fontSize: 25 }}>
          <span aria-hidden>⚙️</span>
        </button>
      </TriangleTooltip>

      <div style={{ float: "right" }}>
        <TriangleTooltip
          label="Notifications Really Long to Trigger Collision"
          aria-label="3 Notifications"
        >
          <button style={{ fontSize: 25 }}>
            <span>🔔</span>
            <span>3</span>
          </button>
        </TriangleTooltip>
      </div>
    </div>
  );
})();
`}</code></pre>
    <h3 {...{
      "id": "animation"
    }}>{`Animation`}</h3>
    <p>{`Here's some code you can copy/paste to make it happen along with `}<a parentName="p" {...{
        "href": "http://react-spring.io"
      }}>{`React Spring`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import * as React from "react";
import { useTooltip, TooltipPopup } from "@reach/tooltip";
import { useTransition, animated } from "@react-spring/web";

animated.TooltipPopup = animated(TooltipPopup);
animated.TooltipContent = animated(TooltipPopup);

function AnimatedTooltip({ children, ...rest }) {
  const [trigger, tooltip, isVisible] = useTooltip();

  const transitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 1, tension: 500, friction: 40 },
  });

  return (
    <React.Fragment>
      {React.cloneElement(children, trigger)}
      {transitions(
        (styles, item) =>
          item && (
            <animated.TooltipContent {...tooltip} {...rest} style={styles} />
          )
      )}
    </React.Fragment>
  );
}

// Now you can replace \`Tooltip\` with \`AnimatedTooltip\`
function App() {
  return (
    <AnimatedTooltip label="Compose Message">
      <button>📝</button>
    </AnimatedTooltip>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      